.soon {
    padding-top: 144px;
    padding-bottom: 144px;
    overflow-x: hidden;
}

.soonFirst {
    padding-top: 75px;
    padding-bottom: 144px;
    overflow-x: hidden;
}

.container {
    max-width: 1280px;
    padding: 0 4rem;
    margin: 0 auto;
}

.content {
    background-color: #1d1e29;
    padding: 2rem 48px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    border-radius: 1rem;
}

.noticeText {
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 2.25rem;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: #d7fc51;
    margin-bottom: 0.5rem;
}

.title {
    font-family: 'Coalition', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 2.5rem;
    line-height: 3.5rem;
    text-transform: uppercase;
    color: #ffffff;
    margin-bottom: 1.25rem;
}

.mobilePoint {
    position: static;
}

.titleIntro {
    display: flex;
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 1.75rem;
    color: #ffffff;
    margin-bottom: 0.25rem;
    text-align: left;
}

.aboutIntro {
    font-family: 'Industry', serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 1rem;
}

.aboutIntro:last-child {
    margin-bottom: 0;
}

.imgIntro {
    margin-right: 0.5rem;
}

.blockImg {
    height: 1px;
    width: 1px;
    position: relative;
    align-self: flex-end;
}

.blockImgFirst {
    height: 1px;
    width: 560px;
    position: relative;
    align-self: flex-end;
}

.blockImg span {
    width: 400px !important;
    height: 570px !important;
}

.blockImgFirst span {
    width: 597px !important;
    height: 404px !important;
}

.img {
    position: absolute;
    bottom: -35px;
    left: -1.875rem;
    z-index: 10;
}

.imgFirst {
    position: absolute;
    bottom: -35px;
    left: -1.875rem;
    z-index: 10;
}

@media (min-width: 2000px) {
    .container {
        max-width: 80%;
    }

    .blockImg span {
        width: 600px !important;
        height: 770px !important;
    }

    .blockImgFirst span {
        width: 1000px !important;
        height: 1000px !important;
    }

    .img {
        bottom: -54px;
        left: -0.875rem;
    }

    .imgFirst {
        bottom: -49px;
        left: -10rem;
    }
  }

@media (min-width: 1800px) and (max-width: 1920px) {
    .container {
        max-width: 100%;
    }
    .blockImg span {
        width: 600px !important;
        height: 770px !important;
    }

    .blockImgFirst span {
        width: 1000px !important;
        height: 1000px !important;
    }

    .img {
        bottom: -54px;
        left: -0.875rem;
    }

    .imgFirst {
        bottom: -49px;
        left: -10rem;
    }
}

@media only screen and (max-width: 1240px)  {
    .content {
        grid-template-columns: 1fr 1fr;
    }

    .img {
        left: 50px;
    }

    .imgFirst {
        bottom: 0.625rem;
        left: 75px;
        width: 500px;
    }
}

@media only screen and (max-width: 1024px) {
    .soon {
        padding-top: 396px;
        padding-bottom: 101px;
    }

    .container {
        padding: 0 1rem;
    }

    .content {
        padding: 1.5rem 1rem 48px 1rem;
        display: flex;
        flex-wrap: wrap-reverse;
        border-radius: 1rem;
    }

    .blockImg {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .blockImg span {
        width: 250px !important;
        height: 350px !important;
    }

    .blockImgFirst {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    .blockImgFirst span {
        width: 597px !important;
        height: 404px !important;
    }

    .mobilePoint {
        position: relative;
    }

    .img {
        position: absolute;
        top: -272px;
        left: -260px;
        z-index: -1;
    }

    .imgFirst {
        visibility: hidden;
    }

    .noticeText {
        font-size: 1rem;
        line-height: 1.75rem;
        letter-spacing: 0.34em;
    }

    .title {
        font-size: 1.5rem;
        line-height: 2.25rem;
        margin-bottom: 0.75rem;
    }

    .titleIntro {
        font-size: 1.375rem;
        margin-bottom: 0;
    }

    .aboutIntro {
        line-height: 1.5rem;
        margin-bottom: 0.75rem;
    }

    .aboutIntro:last-child {
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 768px) {
    .soon {
        padding-top: 200px;
    }

    .blockImg span {
        width: 165px !important;
        height: 250px !important;
    }

    .blockImgFirst span {
        width: 165px !important;
        height: 250px !important;
    }

    .img {
        position: absolute;
        top: -185px;
        left: -168px;
        z-index: -1;
    }

    .imgFirst {
        visibility: hidden;
    }

}
